import Vue from 'vue'

Vue.directive('first-word', {
  bind: node => {
    const text = Array.from(node.getElementsByClassName('js-text'))
    const zero = 0
    if (zero < text.length) {
      text.forEach(el => {
        const textFirstWord = el.innerText.split(' ')[0]
        el.innerText = el.innerText.replace(/[^\s]*/, '')
        const firstWord = document.createElement('span')
        firstWord.classList.add('ds-caption--color_gold')
        firstWord.innerText = textFirstWord
        node.insertBefore(firstWord, el)
      })
    }
  },
})
