const UiSlider = () => import('./UiSlider')
const UiVideoOpener = () => import('./UiVideoOpener')
const UiTabs = () => import('./UiTabs')
const UiGallery = () => import('./UiGallery')
const Renderer = () => import('./Renderer')

export default function installComponents(Vue) {
  Vue.component('UiSlider', UiSlider)
  Vue.component('UiVideoOpener', UiVideoOpener)
  Vue.component('UiTabs', UiTabs)
  Vue.component('UiGallery', UiGallery)
  Vue.component('Renderer', Renderer)
}
