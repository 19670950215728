<template lang="pug">
  include /mixins.pug
  div(
    v-bem='{"appearance": "bordered"}'
  )
    component(
      :is="element",
      :value.prop='value',
      class='d-control-input__element'
      ref='input'
      v-bem.element=''
      v-on="listeners"
      :id='id',
      v-bind='$attrs'
      :type='type'
      v-model='model',
    )
      template(
        v-if='noOptions'
        slot="noOptions"
      )
        span {{ noOptions }}
      template(
        v-slot:[name]="binded"
        v-for="(name, key) in eSlots"
      )
        slot(
          v-bind="binded"
          :name="key"
        )
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :labelText='labelText',
      :input-label='inputLabel',
      :is-required='isRequired'
    )
    +b.change-type.I(
      v-if='type == "password" '
      @click.prevent='changeType()',
      :class='!show ? "icon-eye-off" : "icon-eye"'
    )
</template>

<script>
/* eslint-disable */
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'labelComponent',
    'id',
    'value',
    'labelText',
    'inputLabel',
    'required',
    'isRequired',
    'providerContext',
    'type',
    'element',
    'mask',
    'noOptions',
  ],
  block: 'd-control-input',
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
    },
  },
  data() {
    return {
      input: '',
      show: false,
    }
  },
  computed: {
    eSlots() {
      // TODO ADD SIMPLE SLOTS, NOT SCOPED
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: $event => { this.$emit('input', $event ? $event.id ? $event : ($event.type ? $event.target[this.getKey()] : $event) : $event) },
      }
    },
    model: {
      get() {
        // return this.input
        return this.input = this.value
      },
      set(e) {
        // console.log(e)
        this.input = e
      },
    },
  },
  methods: {
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
  },
}
</script>
