import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { app } from '@app/main'

Object.entries(rules).forEach(([rule, validation]) => {
  extend(rule, {
    ...validation,
  })
})

extend('phoneNumber', {
  validate(value) {
    const phoneLength = 12
    return phoneLength === value.replace(/\D/g, '').toString().length
  },
  message() {
    return app._('Введіть повний номер телефону.')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('Це поле обов`язкове.')
  },
})

extend('email', {
  ...rules.email,
  message() {
    return app._('Поле має бути адресою електронної пошти.')
  },
})

// extend('min_value', {
//   ...rules.min_value,
//   message() {
//     return app._('Сумма должна быть больше 0.')
//   },
// })

extend('min', {
  ...rules.min,
  message(value, sec) {
    return app._('Кількість символів має бути не менше ') + sec.length
  },
})

extend('max', {
  ...rules.max,
  message(value, sec) {
    return app._('Кількість символів має бути не більше ') + sec.length
  },
})

extend('alpha', {
  ...rules.alpha,
  message() {
    return app._('Поле може містити тільки букви')
  },
})

extend('numeric', {
  ...rules.numeric,
  message() {
    return app._('Поле може містити тільки цифри')
  },
})
