import Vue from 'vue'

Vue.directive('height-group', {
  componentUpdated: node => {
    const block = Array.from(node.getElementsByClassName('js-height-block'))
    const zero = 0
    if (zero < block.length) {
      const arr = []
      const lg = 992
      if (window.innerWidth >= lg) {
        const calcHeight = () => {
          block.forEach(el => {
            arr.push(el.offsetHeight)
          })
          const maxHeight = Math.max(...arr)
          block.forEach(el => {
            el.style.height = `${maxHeight}px`
          })
        }
        window.addEventListener('resize', calcHeight)
        window.addEventListener('load', calcHeight)
      }
    }
  },
})
