import { createI18n } from '@/i18n'

export function createApp({ Vue, I18n }) {
  const i18n = createI18n({ Vue, I18n })
  const app = new Vue({
    i18n,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  return { app, i18n }
}
