import Vue from 'vue'

Vue.directive('indexing-container', {
  bind: node => {
    const elem = Array.from(node.getElementsByClassName('js-gallery-item'))
    const zero = 0
    if (zero < elem.length) {
      // console.log(elem)
      elem.forEach((el, index) => {
        el.setAttribute('data-index', index)
      })
    }
  },
})
