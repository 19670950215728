import Search from './Search'
import Sidebar from './Sidebar'
import UiElements from './UiElements'
import Forms from './Forms'
import ShareButton from './ShareButton'
import Controls from './Controls'
import Gallery from './Gallery'
import News from './News'
import Pagination from './Pagination'
import GoOnTop from './GoOnTop'
import PlayerStats from './PlayerStats'
import Team from './Team'
import FanZone from './FanZone'
import Video from './Video'
import GameCalendar from './GameCalendar'
import Infrastructure from './Infrastructure'

export default function installComponents(Vue) {
  Vue.use(Search)
  Vue.use(Sidebar)
  Vue.use(UiElements)
  Vue.use(Forms)
  Vue.use(ShareButton)
  Vue.use(Controls)
  Vue.use(Gallery)
  Vue.use(News)
  Vue.use(Pagination)
  Vue.use(GoOnTop)
  Vue.use(PlayerStats)
  Vue.use(Team)
  Vue.use(FanZone)
  Vue.use(Video)
  Vue.use(GameCalendar)
  Vue.use(Infrastructure)
}
